<template>
    <div class="w-full flex items-center justify-center">
        <div class="text-center">
            <h2 class="font-semibold text-5xl mb-2">Page not found</h2>
            <p class="text-2xl mb-8 text-gray-600">Oops! We couldn't find that page!</p>
            <router-link :to="{name: 'home' }">
                <button class="btn-primary">Go back home</button>
            </router-link>
        </div>
    </div>
</template>